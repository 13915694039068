import React, { ReactNode } from "react";
import {
  AlignLeft,
  AlignCenter,
  AlignRight,
  Carousel,
  List,
  Grid,
  MarginTop,
  MarginBottom,
  MarginLeft,
  MarginRight,
  RadiusTopLeft,
  RadiusBottomLeft,
  RadiusBottomRight,
  RadiusTopRight,
  LandingPage,
  Checkmark,
  Warning,
  Ban,
  BlockButton,
  BlockConsents,
  BlockDisclaimer,
  BlockDivider,
  BlockError,
  BlockForm,
  BlockHeroImage,
  BlockFeedback,
  BlockImage,
  BlockLogo,
  Product,
  BlockText,
  BlockTitle,
  Question,
  Url,
  TrackingCode,
  Home,
  BlockProduct,
  Copy,
} from "@sopost/react-components/dist/svg";
import { PathElement } from "../../builder/types/path";
import { Option } from "../../builder/types/option";
import {
  BlockCTAButton,
  BlockPositiveNegative,
  BlockStarRating,
  BlockYesNo,
  BlockThankYou,
  BlockIntro,
  BlockPin,
  IconDateQuestion,
  BlockFacebook,
  BlockTiktok,
  BlockYoutube,
  BlockInstagram,
  BlockSnapchat,
  BlockPinterest,
  BlockHTML,
  BlockX,
  BlockRepeatFeedback,
  BlockStandardFeedback,
  IconStarRating,
  GTM,
  Meta,
  Snapchat,
  Tiktok,
  Pinterest,
  BlockSocialIcons,
} from "../Icons";

import { Icon } from "@sopost/ui";
import { mdiShare, mdiApplicationOutline, mdiUploadBox } from "@sopost/icons";

export const defaultIconSize = 24;

export const globalBackgroundPath = ["branding", "colours", "block_background"];

type IconDetails =
  | {
      source: "parent";
      hideLabel: boolean;
      parentPath: PathElement;
      imgPaths: PathElement[];
      bgPaths: PathElement[];
    }
  | {
      source: "import";
      hideLabel: boolean;
      image: ReactNode;
    }
  | {
      source: "import_group";
      hideLabel: boolean;
      images: { [key: string]: Option<ReactNode> };
    };

const blockIcons = {
  cookies: <BlockButton />,
  image: <BlockImage />,
  image_v2: <BlockImage />,
  logo_bar: <BlockLogo />,
  logo_bar_v2: <BlockLogo />,
  hero: <BlockHeroImage />,
  product_image: <Product />,
  title: <BlockTitle />,
  title_v2: <BlockTitle />,
  text: <BlockText />,
  text_v2: <BlockText />,
  form: <BlockForm />,
  form_v2: <BlockForm />,
  readonly_address: <BlockForm />,
  consents: <BlockConsents />,
  consents_v2: <BlockConsents />,
  disclaimer: <BlockDisclaimer />,
  terms: <BlockButton />,
  submit_button: <BlockButton />,
  submit_button_v2: <BlockButton />,
  pointer: <BlockButton />,
  pointer_v2: <BlockButton />,
  separator: <BlockDivider />,
  products: <Product />,
  status: <BlockError />,
  external_cta: <BlockButton />,
  call_to_action: <BlockButton />,
  call_to_action_v2: <BlockButton />,
  footer: <BlockText />,
  custom_questions: <BlockForm />,
  custom_questions_v2: <BlockForm />,
  product_selection: <Product />,
  product_selection_v2: <Product />,
  product_selection_v3: <Product />,
  landing_page_url: <BlockText />,
  landing_page_button: <BlockButton />,
  landing_page_button_v2: <BlockButton />,
  direct_to_feedback: <BlockFeedback />,
  direct_to_feedback_v2: <BlockFeedback />,
  per_product_page: <BlockFeedback />,
  syndicator_questions: <Question />,
  syndicator_questions_v2: <Question />,
  progress_button: <BlockButton />,
  progress_button_v2: <BlockButton />,
  star_rating: <BlockStarRating />,
  faqs: <Question />,
  faqs_v2: <Question />,
  order_details: <Home />,
  order_details_v2: <Home />,
  shop_now_button: <BlockProduct />,
  shop_now_button_v2: <BlockProduct />,
  social_icons: <BlockSocialIcons />,
  pin: <BlockPin />,
  youtube: <BlockYoutube />,
  x: <BlockX />,
  pinterest: <BlockPinterest />,
  facebook: <BlockFacebook />,
  instagram: <BlockInstagram />,
  snapchat: <BlockSnapchat />,
  tiktok: <BlockTiktok />,
  custom_html: <BlockHTML />,
  gifting: <Icon path={mdiShare} className="m-px h-5 w-5" />,
  image_upload: <Icon path={mdiUploadBox} className="m-px h-5 w-5" />,
};

export const iconLibrary = (
  iconSize?: number,
): { [key: string]: Option<IconDetails> } => {
  const size = iconSize ?? defaultIconSize;

  const sideIcons: IconDetails = {
    source: "import_group",
    hideLabel: true,
    images: {
      top: <MarginTop size={size} />,
      bottom: <MarginBottom size={size} />,
      left: <MarginLeft size={size} />,
      right: <MarginRight size={size} />,
    },
  };

  const cornerIcons: IconDetails = {
    source: "import_group",
    hideLabel: true,
    images: {
      top_left: <RadiusTopLeft size={size} />,
      top_right: <RadiusTopRight size={size} />,
      bottom_left: <RadiusBottomLeft size={size} />,
      bottom_right: <RadiusBottomRight size={size} />,
    },
  };

  const integrationsIcons: IconDetails = {
    source: "import_group",
    hideLabel: false,
    images: {
      google_tag_manager: <GTM size={size} />,
      meta_pixel: <Meta size={size} />,
      snap_pixel: <Snapchat size={size} />,
      tiktok_pixel: <Tiktok size={size} />,
      pinterest_pixel: <Pinterest size={size} />,
      none: <Ban size={size} />,
    },
  };

  const questionsIcons: IconDetails = {
    source: "import_group",
    hideLabel: true,
    images: {
      short_answer: <BlockTitle />,
      multiple_choice: <BlockForm />,
      star_rating: <IconStarRating />,
      date_input: <IconDateQuestion />,
      product_multiple_choice: <BlockForm />,
    },
  };

  return {
    blend_mode: {
      source: "parent",
      hideLabel: false,
      parentPath: "image",
      imgPaths: ["image", "image"],
      bgPaths: ["style", "callout"],
    },

    layout: {
      source: "import_group",
      hideLabel: true,
      images: {
        carousel: <Carousel size={size} />,
        list: <List size={size} />,
        grid: <Grid size={size} />,
      },
    },

    direct_to_feedback: {
      source: "import_group",
      hideLabel: true,
      images: {
        cta_button: <BlockCTAButton size={75} />,
        star_rating: <BlockStarRating size={75} />,
        yes_no: <BlockYesNo size={75} />,
        positive_negative: <BlockPositiveNegative size={75} />,
      },
    },

    syndicator_questions: {
      source: "import_group",
      hideLabel: true,
      images: {
        star_rating: <BlockStarRating size={24} />,
        nickname: <BlockTitle />,
        review: <BlockText />,
      },
    },

    syndicator_questions_v2: {
      source: "import_group",
      hideLabel: true,
      images: {
        star_rating: <BlockStarRating size={24} />,
        nickname: <BlockTitle />,
        review: <BlockText />,
      },
    },

    alignment: {
      source: "import_group",
      hideLabel: true,
      images: {
        left: <AlignLeft size={size} />,
        center: <AlignCenter size={size} />,
        centre: <AlignCenter size={size} />,
        right: <AlignRight size={size} />,
      },
    },

    margin: sideIcons,
    padding: sideIcons,

    border_radius: cornerIcons,
    corner_radius: cornerIcons,
    custom_shape: cornerIcons,

    pages: {
      source: "import_group",
      hideLabel: true,
      images: {
        landing: <LandingPage fill="var(--neutral)" />,
        completion: <Checkmark fill="var(--success)" />,
        terms: <BlockConsents fill="var(--neutral)" />,
        details: <BlockText fill="var(--neutral)" />,
        error: <Warning fill="var(--danger)" />,
        per_product_page: <BlockRepeatFeedback />,
        standard_page: <BlockStandardFeedback />,
        thank_you: <BlockThankYou />,
        intro: <BlockIntro />,
      },
    },

    icons: {
      source: "import_group",
      hideLabel: true,
      images: {
        facebook: <BlockFacebook />,
        x: <BlockX />,
        pintrest: <BlockPinterest />,
        snapchat: <BlockSnapchat />,
        tiktok: <BlockTiktok />,
        youtube: <BlockYoutube />,
        instagram: <BlockInstagram />,
      },
    },

    landing: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    completion: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    error: {
      source: "import_group",
      hideLabel: true,
      images: {
        out_of_stock: <Copy fill="var(--neutral)" />,
        closed: <Copy fill="var(--neutral)" />,
        claimed: <Copy fill="var(--neutral)" />,
      },
    },

    out_of_stock: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    closed: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    claimed: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    blocks: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    intro: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    thank_you: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    per_product_page: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    standard_page: {
      source: "import_group",
      hideLabel: true,
      images: blockIcons,
    },

    questions: questionsIcons,

    code: integrationsIcons,

    short_answer: questionsIcons,
    star_rating: questionsIcons,
    multiple_choice_single_response: questionsIcons,
    multiple_choice_multi_response: questionsIcons,
    product_selection: questionsIcons,
    answers: {
      source: "import",
      hideLabel: true,
      image: <BlockTitle />,
    },
    items: {
      source: "import",
      hideLabel: true,
      image: <Question />,
    },

    fields: {
      source: "import_group",
      hideLabel: true,
      images: {
        name: <BlockForm />,
        first_name: <BlockForm />,
        last_name: <BlockForm />,
        email: <BlockForm />,
        phone: <BlockForm />,
        address_line_1: <BlockForm />,
        address_line_2: <BlockForm />,
        postcode: <BlockForm />,
        district: <BlockForm />,
        town: <BlockForm />,
        delivery_notes: <BlockForm />,
        generic: <BlockForm />,
        nickname: <BlockTitle size={size} />,
        star_rating: <IconStarRating size={size} />,
        review: <BlockText size={size} />,
        review_title: <BlockTitle size={size} />,
        date_of_birth: <BlockForm />,
        gender: <BlockForm />,
      },
    },

    // TikTok
    custom_question: {
      source: "import",
      hideLabel: true,
      image: <BlockConsents fill="var(--neutral)" />,
    },

    tiktok_consent: {
      source: "import",
      hideLabel: true,
      image: <BlockConsents fill="var(--neutral)" />,
    },

    // Snapchat
    custom_disclosure: {
      source: "import",
      hideLabel: true,
      image: <BlockConsents fill="var(--neutral)" />,
    },

    settings: {
      source: "import_group",
      hideLabel: true,
      images: {
        page: <Icon path={mdiApplicationOutline} className="m-px h-5 w-5" />,
        frame_ancestors: <Url />,
        tracking_codes: <TrackingCode />,
      },
    },
  };
};
